/**
 *
 * @returns {*|AxiosPromise}
 * @param linkId
 */
export function showLink(linkId) {
    return axios({
        url: `/api/links/${linkId}`,
        method: 'get',
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function saveLink(data) {
    return axios({
        url: `/api/links/`,
        method: 'post',
        data: data,
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function saveClientPropositions(data) {
    return axios({
        url: `/api/links/save-client-propositions`,
        method: 'post',
        data: data,
    });
}

/**
 *
 * @param data
 * @returns {*}
 */
export function sendMailPropositions(data) {
    return axios({
        url: `/api/links/send-mail-propositions`,
        method: 'post',
        data: data,
    });
}


/**
 *
 * @param data
 * @returns {*}
 */
export function saveClientChoice(data) {
    return axios({
        url: `/api/links/save-client-choice`,
        method: 'post',
        data: data,
    });
}


/**
 *
 * @param params
 * @returns {*}
 */
export function listLinks(params) {
    return axios({ url: '/api/links/list',
        method: 'post',
        data: params
    });
}
