<template>
    <div v-click-outside="hide"  class="bottom-block">
        <div v-if="condition.isShow" style="position: relative">
            <div class="more-options">
                <p v-if="showOption('download_draft')" class="option">
                    <span class="icon">
                        <svg style="margin-bottom: 7px;" width="16" height="16"
                             viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                                stroke="#24282C" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                           <path
                                d="M4.6665 6.66666L7.99984 9.99999L11.3332 6.66666"
                                stroke="#24282C" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 10V2" stroke="#24282C" stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <a @click="downloadDraft()" href="javascript:void(0);">
                        <span class="text">Завантажити чернетку</span>
                    </a>
                </p>
                <p v-if="showOption('continue_order')" class="option">
                    <span class="icon">
                       <svg style="margin-bottom: 4px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0415 7.75002C12.0398 8.88767 11.5871 9.97823 10.7827 10.7827C9.97822 11.5871 8.88766 12.0398 7.75001 12.0415C6.61223 12.0399 5.52149 11.5873 4.71686 10.7829C3.91223 9.97844 3.45936 8.8878 3.45751 7.75002C3.45896 6.61203 3.91167 5.52106 4.71636 4.71637C5.52104 3.91169
                                    6.61201 3.45898 7.75001 3.45752C8.70651 3.45752 9.58251 3.77252 10.295 4.30052L9.40401 5.19252L13.619 6.32052L12.489 2.10702L11.544 3.05202C10.4712 2.18166 9.13151 1.70692 7.75001 1.70752C4.41301 1.70902 1.70901 4.41252 1.70801 7.75002C1.70901 11.0875 4.41301 13.7915 7.75001 13.7915C11.087 13.7915 13.7915 11.0875 13.7915 7.75002H12.0415Z" fill="#24282C"/>
                       </svg>

                    </span>
                    <a @click="proceedOrder()" href="javascript:void(0);">
                        <span class="text">Продовжити</span>
                    </a>
                </p>
              <dynamic-download-contract-menu v-if="[4].includes(linkType) && showOption('download_contract') && dataContractExtraFiles" :data-contract-extra-files="dataContractExtraFiles">
              </dynamic-download-contract-menu>
                <p v-if="[4].includes(linkType) && showOption('download_contract') && !dataContractExtraFiles" class="option">
                    <span class="icon">
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none" stroke="#dc3545">
                        <circle cx="6" cy="6" r="4.5"/>
                        <path stroke-linejoin="round" d="M5.8 3.6h.4L6 6.5z"/>
                        <circle cx="6" cy="8.2" r=".6" fill="#dc3545" stroke="none"/>
                      </svg>
                    </span>
                    <a href="javascript:void(0);">
                        <span class="text">Спочатку завантажте ОСЦПВ</span>
                    </a>
                </p>
                <p v-if="showOption('download_contract')" class="option">
                    <span class="icon">
                        <svg style="margin-bottom: 7px;" width="16" height="16"
                             viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                                stroke="#24282C" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M4.6665 6.66666L7.99984 9.99999L11.3332 6.66666"
                                stroke="#24282C" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 10V2" stroke="#24282C" stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <a @click="downloadContract()" href="javascript:void(0);">
                        <span class="text">Завантажити поліс</span>
                    </a>
                </p>
                <p v-if="showOption('proceed_order')" class="option">
                    <span class="icon">
                      <svg style="margin-bottom: 3px;" width="16" height="16"
                           viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <mask id="path-1-inside-1" fill="white">
                        <rect x="1" y="1" width="14" height="14" rx="1"/>
                        </mask>
                        <rect x="1" y="1" width="14" height="14" rx="1"
                              stroke="#24282C" stroke-width="4"
                              mask="url(#path-1-inside-1)"/>
                        <rect x="4" y="5" width="2" height="2" fill="#24282C"/>
                        <rect x="7" y="5" width="5" height="2" fill="#24282C"/>
                        <rect x="4" y="9" width="5" height="2" fill="#24282C"/>
                        <rect x="10" y="9" width="2" height="2" fill="#24282C"/>
                      </svg>
                    </span>
                    <a @click="proceedOrder()" href="javascript:void(0);">
                        <span class="text">Продовжити</span>
                    </a>
                </p>
                <p v-if="showOption('show_link')" class="option">
                    <span class="icon">
                       <svg style="margin-bottom: 3px" width="16" height="16"
                            viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path
                            d="M0.666504 8C0.666504 8 3.33317 2.66667 7.99984 2.66667C12.6665 2.66667 15.3332 8 15.3332 8C15.3332 8 12.6665 13.3333 7.99984 13.3333C3.33317 13.3333 0.666504 8 0.666504 8Z"
                            stroke="#24282C" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path
                            d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                            stroke="#24282C" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </span>
                    <router-link :to="'/links/' + link.id" class="header__logo" tag="a" target="_blank">
                        <span class="text">Переглянути</span>
                    </router-link>
<!--                    <a @click="showLink()" href="javascript:void(0);">-->
<!--                        <span class="text">Переглянути</span>-->
<!--                    </a>-->
                </p>
                <p v-if="showOption('copy_link')" class="option">
                    <span class="icon">
                       <svg style="margin-bottom: 3px" width="16" height="16"
                            viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                                    stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.3335 10H2.66683C2.31321 10 1.97407 9.85952 1.72402 9.60947C1.47397 9.35942 1.3335 9.02028 1.3335 8.66666V2.66666C1.3335 2.31304 1.47397 1.9739 1.72402 1.72385C1.97407 1.4738 2.31321 1.33333 2.66683 1.33333H8.66683C9.02045 1.33333 9.35959 1.4738 9.60964 1.72385C9.85969 1.9739 10.0002 2.31304 10.0002 2.66666V3.33333"
                                      stroke="#24282C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <a @click="copy()" href="javascript:void(0);">
                        <span class="text">Копіювати посилання</span>
                    </a>
                </p>
                <p v-if="showOption('create_order')" class="option">
                    <svg style="margin-bottom: 5px" width="16" height="16"
                         viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <mask id="path-1-inside-1" fill="white">
                            <rect x="1" y="5" width="14" height="10" rx="1"/>
                        </mask>
                        <rect x="1" y="5" width="14" height="10" rx="1"
                              stroke="#24282C" stroke-width="4"
                              mask="url(#path-1-inside-1)"/>
                        <mask id="path-2-inside-2" fill="white">
                            <rect x="4" y="1" width="8" height="6" rx="1"/>
                        </mask>
                        <rect x="4" y="1" width="8" height="6" rx="1"
                              stroke="#24282C" stroke-width="4"
                              mask="url(#path-2-inside-2)"/>
                    </svg>
                    <a @click="createOrder()" href="javascript:void(0);">
                        <span style="margin-left: 3px;">Укласти</span>
                    </a>
                </p>
                <p v-if="showOption('show_mtsbu') && this.isExistMtsbuLink" class="option">
                    <span class="icon">
                        <svg width="16" style="margin-bottom: 5px" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 4.5H7.125V6.25H2.75V13.25H9.75V8.875H11.5V13.25C11.5 13.7141 11.3156 14.1592 10.9874 14.4874C10.6592 14.8156 10.2141 15 9.75 15H2.75C2.28587 15 1.84075 14.8156 1.51256 14.4874C1.18437 14.1592 1 13.7141 1 13.25V6.25C1 5.78587 1.18437 5.34075 1.51256 5.01256C1.84075 4.68437 2.28587 4.5 2.75 4.5ZM12.0373 2.75H8V1H15V8H13.25V4.01175L8.581 8.68075L7.34375 7.4435L12.0373 2.75Z" fill="#24282C"/>
                        </svg>

                    </span>
                    <a @click="openMtsbuLink()" href="javascript:void(0);">
                        <span class="text">Посилання на МТСБУ</span>
                    </a>
                </p>
            </div>
        </div>
        <a @click="handle()" href="javascript:void(0);" class="option image">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" fill="#999DA6"/>
                <circle cx="9" cy="2" r="2" fill="#999DA6"/>
                <circle cx="16" cy="2" r="2" fill="#999DA6"/>
            </svg>
        </a>
    </div>
</template>


<script>
import ClickOutside from 'vue-click-outside'
import { linkMixin } from '@/mixin'
import DynamicDownloadContractMenu from "@/components/app/DynamicDownloadContractMenu.vue";
export default {
    name: 'MoreOptions',
    props: ['link', 'linkStatus', 'linkType', 'isExistMtsbuLink', 'dataContractExtraFiles'],
    mixins: [linkMixin],
    components: {DynamicDownloadContractMenu},
    directives: {ClickOutside},
    data: function () {
        return {
            contractExtraFiles: null,
            condition: {
                isShow: false,
            },
        }
    },
    computed: {
        listOptions: function() {
            let list = {};

            list[this.sentStatus] = [
                // 'download_draft',
                // 'continue_order',
                'show_link',
                'copy_link',
            ];
            list[this.receivedStatus] = [
                // 'download_draft',
                // 'confirm_order',
                'show_link',
                'copy_link',
                'create_order',
                'continue_order',
                //'all_payment_ways',
                // 'payment_invoice',
                // 'instant_loan',
                //'show_mtsbu',
            ];

            list[this.completedStatus] = [
                // 'download_draft',
                'download_contract',
                'show_mtsbu',
                'show_link',
                'copy_link',
                //'all_payment_ways',
                // 'payment_invoice',
                // 'instant_loan',

            ];

            list[this.overdueStatus] = [
                //'download_draft',
                'show_link',
                'copy_link',
            ];

            // list[this.canceledStatus] = [
            //     'show_order',
            //     'copy_order',
            // ];

            return list;
        },
    },
    created() {
      this.contractExtraFiles =  this.dataContractExtraFiles;
    },
    watch:{
      dataContractExtraFiles: {
        handler(newVal, oldVal) {
          this.contractExtraFiles =  newVal;
        },
        deep: true
      }
    },
    events: {},
    methods: {
        confirmOrder: function() {
            this.hide();
            return this.$emit('confirm-order');
        },
        openMtsbuLink: function() {
            this.hide();
            return this.$emit('open-mtsbu-link');
        },
        getPaymentInvoice: function() {
            this.hide();
            return this.$emit('get-payment-invoice');
        },
        getInstantLoan: function() {
            this.hide();
            return this.$emit('get-instant-loan');
        },
        getAllPaymentWays: function () {
            this.hide();
            return this.$emit('get-all-payment-ways');
        },
        showLink: function() {
            this.hide();
            return this.$emit('show-link');
        },
        showOption: function(name) {
            // if (this.linkType == 4 && !['download_contract','show_order'].includes(name)) {
            //     return false;
            // }
            // if (this.linkType == 6 && !['download_draft', 'download_contract','show_order',].includes(name)) {
            //     return false;
            // }
            if (this.linkStatus == 5 && ['download_contract'].includes(name) && !this.link.orderData) {
                return false;
            }
            if (this.linkStatus == 2 && ['create_order'].includes(name) && (this.link.orderData && this.link.orderData?.statusId != -1)) {
                return false;
            }
            if (this.linkStatus == 2 && ['continue_order'].includes(name) && (!this.link.orderData || this.link.orderData?.statusId == -1)) {
                return false;
            }
             return this.listOptions[this.linkStatus].indexOf(name) !== -1;
            // if (['copy_order','show_order'].includes(name)) {
            //     return true;
            // }
        },
        copy: function () {
            this.hide();
            return this.$emit('copy-link');
        },
        downloadDraft: function () {
            this.hide();
            return this.$emit('download-draft');
        },
        downloadContract: function () {
            this.hide();
            return this.$emit('download-contract');
        },
        createOrder: function () {
            this.hide();
            return this.$emit('create-order');
        },
        proceedOrder: function () {
            this.hide();
            return this.$emit('proceed-order');
        },
        hide: function () {
            return this.condition.isShow = false;
        },
        handle: function () {
            return this.condition.isShow = ! this.condition.isShow
        }
    }
}
</script>

<style lang="scss" scoped>
.more-options {
    top: 20px;
    z-index: 2;
    width: 261px;
    border-radius: 5px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

    @media (min-width: 992px){
        left: -138px;
    }


    .option {
        margin-top: 10px;
        margin-left: 21px;

        a {
            color: #24282C;
            font-size: 14px;
            line-height: 21px;
            font-family: Circe;
            font-style: normal;
            font-weight: normal;

            .icon {

            }

            .text {
                height: 21px;
                margin-left: 5px;
            }
        }
    }
}
</style>
