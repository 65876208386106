<template>
    <div class="container pl-0" style="margin-bottom: 30px;">
        <div class="insurance-history__menu__filters" >
            <button @click="filterStatusWrap()" style="width: 120px;text-align: left">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="4" width="24" height="2" fill="#24282C"/>
                    <rect y="18" width="24" height="2" fill="#24282C"/>
                    <circle cx="8" cy="5" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                    <circle cx="16" cy="19" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                </svg>
                {{ condition.isShowFilter ? 'Приховати' : 'Фільтри'}}
            </button>
            <button v-if="! isLoadData && (orders && orders.length > 0)" @click="getXls()">
                <div v-if="isLoadXml"
                    class="option-loader spinner-border"
                    style="height: 22px; width: 22px; margin-right: 12px;"
                    role="status">
                </div>
                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="3" fill="#207245"/>
                    <path d="M6.16667 4.5C5.24167 4.5 4.5 5.24167 4.5 6.16667V17.8333C4.5 18.7583 5.24167 19.5 6.16667 19.5H17.8333C18.7583 19.5 19.5 18.7583 19.5 17.8333V6.16667C19.5 5.24167 18.7583 4.5 17.8333 4.5H6.16667ZM6.16667 6.16667H17.8333V17.8333H6.16667V6.16667ZM12.8333 12L15.5 16.1667H13.8333L12 13L10.1667 16.1667H8.5L11.1667 12L8.5 7.83333H10.1667L12 11L13.8333 7.83333H15.5L12.8333 12Z" fill="white"/>
                </svg>
                Експорт в Excel
            </button>
        </div>
        <transition name="fade">
            <div v-show="condition.isShowFilter" class="menu filters mt-4">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <div class="filter-title" style="">
                        Тип продукту
                    </div>
                    <el-select clearable  v-model="listQuery.productId"
                               class="form-control-el"
                               multiple placeholder="Виберiть тип продукту">
                        <el-option v-for="item in orderTypesList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </div>
<!--                <div class="col-sm-4 form-group">-->
<!--                    <div class="filter-title" style="">-->
<!--                        Компанії-->
<!--                    </div>-->
<!--                    <el-select v-model="listQuery.companyIds"-->
<!--                               class="form-control-el"-->
<!--                               multiple placeholder="Виберіть компанії">-->
<!--                        <el-option v-for="item in companiesList"-->
<!--                                   :key="item.id"-->
<!--                                   :label="item.companyName"-->
<!--                                   :value="item.id">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </div>-->
                <div class="col-sm-4 form-group">
                    <div class="filter-title">
                        Дата створення
                    </div>
                    <el-date-picker
                        class="form-control-el w-100"
                        v-model="dateFilter"
                        type="daterange"
                        align="right"
                        format="dd-MM-yyyy"
                        start-placeholder="Від"
                        :picker-options="pickerOptions"
                        end-placeholder="До"
                        @change="refreshDate"
                    />
                </div>
                <div v-if="! isPartner()" class="col-sm-4 form-group">
                    <div class="filter-title">
                        Торгова точка
                    </div>
                    <el-select v-model="listQuery.partnerIds"
                               class="form-control-el"
                               multiple
                               placeholder="Виберіть торгову точку">
                        <el-option v-for="item in partnersList"
                                   :key="item.id"
                                   :label="item.fullName"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 form-group">
                    <div class="filter-title">ПІБ клієнта</div>
                    <div>
<!--                        <input class="form-control" v-model="listQuery.insurerName"/>-->
                        <input class="form-control" v-model="listQuery.clientName"/>
                    </div>
                </div>
                <div class="col-sm-4 form-group">
                    <div class="filter-title">
                        Дата закінчення
                    </div>
                    <el-date-picker
                        class="form-control-el w-100"
                        v-model="dateExpiresFilter"
                        type="daterange"
                        align="right"
                        format="dd-MM-yyyy"
                        start-placeholder="Від"
                        :picker-options="pickerOptions"
                        end-placeholder="До"
                        @change="refreshDateExpires"
                    />
                </div>
                <div class="col-sm-4 form-group">
                    <div class="filter-title">ID посилання</div>
                    <div class="form-group">
                        <input class="form-control" v-model="listQuery.linkId">
                    </div>
                </div>
            </div>
<!--            <div class="row" v-if="!isPartner && userInfo && userInfo.type_id == 1">-->

            <hr>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <button @click="getList()" class="btn pl-0 w-100 btn-primary">
                        Застосувати
                    </button>
                </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
import {companyMixin, orderMixin, partnerMixin, userMixin} from '@/mixin'

export default {
        mixins: [userMixin, partnerMixin, companyMixin, orderMixin],
        name: 'FilterBlock',
        props: ['isLoadXml', 'isLoadData', 'orders'],
        data: function () {
            return {
                dateFilter: new Date(),
                dateExpiresFilter: new Date(),
                condition: {
                    isShowBlock: false,
                    isShowFilter: false,
                    isShowChildrenUser:false
                },
                listQuery: {
                    userId: null,
                    clientName: null,
                    linkId: null,
                    productId: null,
                    typeId: null,
                    mtsbuCode: null,
                    insurerName: null,
                    partnerIds: null,
                    toDate: moment().calendar(),
                    fromDate: moment().subtract(2, 'days').calendar(),
                    expiresTo: moment().calendar(),
                    expiresFrom: moment().subtract(2, 'days').calendar(),
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            }
        },
        created() {
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 2);
            oneWeekAgo.setHours(0, 0, 0, 0);
            this.dateFilter = [oneWeekAgo, moment()];

            this.listQuery.fromDate = moment(this.dateFilter[0]).format();
            this.listQuery.toDate =  moment(this.dateFilter[1]).format();
            // expires
            let expiresTo = moment().endOf('day');
            this.dateExpiresFilter = [oneWeekAgo, expiresTo];
            this.listQuery.expiresFrom =  moment(this.dateExpiresFilter[0]).format();
            this.listQuery.expiresTo = moment(this.dateExpiresFilter[1]).format();
        },
        mounted: function () {

        },
        computed: {
            partnersList() {
                return this.$store.getters.ALL_PARTNERS;
            },
            linkStatuses() {
                return this.$store.getters.LINK_STATUSES;
            },
            orderTypesList() {
                let allOrderTypes = this.$store.getters.ALL_ORDER_TYPES;
                return allOrderTypes.filter(type => [1, 3, 5].includes(type.id));
            },
        },
        methods: {
            getQuery() {
                this.listQuery.fromDate = moment(this.dateFilter[0]).format();
                this.listQuery.toDate =  moment(this.dateFilter[1]).format();

                this.listQuery.expiresFrom =  moment(this.dateExpiresFilter[0]).format();
                this.listQuery.expiresTo = moment(this.dateExpiresFilter[1]).format();

                return this.listQuery;
            },
            refreshDate: function() {
                this.listQuery.fromDate = moment(this.dateFilter[0]).format();
                this.listQuery.toDate =  moment(this.dateFilter[1]).format();
            },
            refreshDateExpires: function() {
                this.listQuery.expiresFrom =  moment(this.dateExpiresFilter[0]).format();
                this.listQuery.expiresTo = moment(this.dateExpiresFilter[1]).format();
            },
            filterStatusWrap: function(scroll = false) {
                this.condition.isShowFilter = ! this.condition.isShowFilter;

                if (scroll) {
                    let filter = this.$el.querySelector(".insurance-history__menu__filters");
                    filter.scrollTop = filter.scrollHeight;
                }
            },
            getXls : function() {

                return this.$emit('download-xls');
            },
            getList() {
                this.condition.isShowFilter = false;
                return this.$emit('init-links-list');
            },
            initOrdersList: function() {
                return this.$emit('init-links-list');
            }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
    .clients button{
      display: initial;
    }
</style>
